body, #root {
    width: 100% !important;
    height: 100% !important;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5e6e82;
    text-align: left;
    background-color: #edf2f9;
}

.navbar-vertical .navbar-nav .nav-link-icon {
    width: 1rem !important;
    min-width: 1rem !important;
    margin-right: .5rem;
}

.btn-primary-fca {
    color: #fff !important;
    background-color: #5c89db !important;
    padding: 18px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;
    border: none;
}

.btn-primary-fca:hover {
    color: #fff !important;
    background-color: #6db3ff !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sidebar {
    background-color: rgba(237, 242, 249, .96);
    flex-basis: auto;
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgb(0 0 0 /10%);
    height: 100vh;
}

#sidebarMenu {
    height: 100vh;
}

.sidebarMenu ul li {
    list-style: none;
}

#sidebarMenu .active {
    color: #2c7be5 !important;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.sidebar .nav-link {
    font-weight: 500;
    color: #5e6e82;
}

#brands-collapse li {
    color: #5e6e82;
    white-space: nowrap;
    font-weight: 500;
    list-style: none;
}
.chip {
    display: inline-block;
    padding: 0 15px;
    height: 25px;
    font-size: 10px;
    margin-left: 2px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #d9d8d8;
}

.info-gb {
    background: #F2F2F2;
}

/*form*/
.form-card {
    background: #F2F2F2;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    padding: 5rem;
}


.login-card {
    font-family: sans-serif;
    width: 300px;
    margin: 3em auto;
    border-radius: .5rem;
    background-color: #ffff;
    padding: 1.8rem;
    box-shadow: 2px 5px 20px rgb(0 0 0 / 10%);
}

.title {
    text-align: center;
    font-weight: bold;
    margin: 0;
}

.subtitle {
    text-align: center;
    font-weight: bold;
}


.alert-danger {
    color: #721c24;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: .5rem 0 !important;
}

.icons-color {
    color: #7E97A6;
}

.hover-red:hover {
    color: red;
}

.hover-yellow:hover {
    color: #F8B500;
}

.hover-view:hover {
    color: #D3D5FD;
}


/*fix css*/
.bg-gradient {
    background-image: inherit !important;
}

.btn-falcon-default:not(:disabled):not(.disabled).active, .btn-falcon-default:not(:disabled):not(.disabled):active, .show > .btn-falcon-default.dropdown-toggle {
    color: #fff !important;
    background-color: #2C7BE5 !important;
    border-color: #2C7BE5 !important;
}

.btn-group button {
    margin-right: 10px;
}

.btn-group button {
    border-top-right-radius: .2rem !important;
    border-bottom-right-radius: .2rem !important;
    border-radius: .2rem !important;
}

.collapse-btn {
    background: transparent !important;
    color: #232e3c !important;
    border: none !important;
    text-align: left !important;
}

/*.collapse-btn[aria-expanded="true"]::after {*/
/*    content: "\f077";*/
/*}*/
.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(116, 129, 148, .3);
}

.scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background-color: transparent;
}

/* charts  */
.apexcharts-menu-item {
    color: #000000;
}

/*table actions dropdownmenu */

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

.fs--1 {
    font-size: .83333rem;
}

.svg-inline--fa.fa-w-16 {
    width: 1em;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.btn-reveal-trigger .btn-reveal:active, .btn-reveal-trigger .btn-reveal:active:focus, .btn-reveal-trigger .btn-reveal:focus {
    box-shadow: none;
    color: #0b1727 !important;
    background-color: #e6e6e6 !important;
    border-color: #dfdfdf !important;
}

.btn-reveal-trigger:focus .btn-reveal:active, .btn-reveal-trigger:focus .btn-reveal:focus, .btn-reveal-trigger:focus .btn-reveal:hover, .btn-reveal-trigger:hover .btn-reveal:active, .btn-reveal-trigger:hover .btn-reveal:focus, .btn-reveal-trigger:hover .btn-reveal:hover {
    color: #0b1727 !important;
}

.btn-reveal {

}

.nav-item::before {
    transition: all .2s ease-in-out !important;
    transform-origin: center !important;
    transition-property: transform !important;
}

.dropdown-indicator:after {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    height: .4rem;
    width: .4rem;
    border-right: 1px solid #5e6e82;
    border-bottom: 1px solid #5e6e82;
    top: 50%;
    transform: translateY(-50%) rotate(
            45deg
    );
    transition: all .2s ease-in-out;
    transform-origin: center !important;
    transition-property: transform !important;
}

.dropdown-indicator[aria-expanded=true]:after {
    transform: translateY(-50%) rotate(
            225deg
    ) !important;
}

/*tables styles*/
table {
    font-size: 12px;
}

.btn-reveal:hover {
    box-shadow: 0 0 0 1px rgb(43 45 80 / 10%), 0 2px 5px 0 rgb(43 45 80 / 8%), 0 1px 1.5px 0 rgb(0 0 0 / 7%), 0 1px 2px 0 rgb(0 0 0 / 8%);
}


/*details upload btn*/
.file.btn.btn-danger {
    padding: 10px;
    border: 0;
    line-height: 0 !important;
}

.file.btn.btn-danger:hover {
    background: none !important;
}

.sm-delete {
    line-height: 0 !important;
    padding: 0 !important;
}

