/* Container needed to position the button. Adjust the width as needed */
.container {
    position: relative;
    width: 100%;
}

/* Make the image responsive */
.container img {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
}

/* Style the button and place it in the middle of the container/image */
.container .btn {
    position: absolute;
    top: 15px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 16px;
    cursor: pointer;
    right: -5px;
    /* background: transparent !important; */
    /*color: red;*/
    /*border: 1px solid red;*/
    /*line-height: 0;*/
    /*border-radius: 20px;*/
    padding: 1px;
}

